import React from "react";
import "./App.css";
import Header from "./components/Header";
import { Container, Grid } from "@nodeme/grid-react";
import nodeMe from "@ypi/client-sdk";
import Registration from "./components/Registration";
import Preview from "./components/Preview";
import PendingScreen from "./components/PendingScreen";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      error: false,
      offline: false
    };
  }

  componentDidMount() {
    if (Boolean([].find))
      (async () => {
        try {
          await nodeMe.init({
            idp:
              "https://europe-west1-authentication-cfd43.cloudfunctions.net/app",
            appId: "a9afa50a-bfbd-4a29-96cb-036653a184cb",
            apiKey: "FpbaSFN2ZQnQrE4QUp0Tm2bVGpAY6xfR",
            domain: "localhost:3000",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk0M2E5YmE5LWE4NmQtNDk4Ni1iZDRhLTc0NDZjMDNiODE4NiIsImlzcyI6Imh0dHBzOi8vZXVyb3BlLXdlc3QxLWF1dGhlbnRpY2F0aW9uLWNmZDQzLmNsb3VkZnVuY3Rpb25zLm5ldC9hcHAiLCJhdWQiOiJodHRwczovL2V1cm9wZS13ZXN0MS1hdXRoZW50aWNhdGlvbi1jZmQ0My5jbG91ZGZ1bmN0aW9ucy5uZXQvYXBwIiwic3ViIjoiMDE0M2U0NmMtOThkOS00YmVlLTkwMDAtYWMxYTAyNTRmYmM4Iiwic2NvcGVzIjpbIkF1dGhlbnRpY2F0aW9uOnVzZXI6cHVibGljOmFwcDpyZWFkIl0sImlhdCI6MTU2MzI3NDMwNn0.nfCuUjauXvo9IQNZ4hGOBxvfks84Ew1wFdXfyzQ-rBw",
            models: [
              {
                name: "Artwork",
                serverName: "Art",
                server:
                  "https://europe-west1-artwork-e7eca.cloudfunctions.net/app",
                type: "resource"
              },
              {
                name: "Registration",
                serverName: "Art",
                server:
                  "https://europe-west1-artwork-e7eca.cloudfunctions.net/app",
                type: "resource"
              }
            ]
          });

          this.setState({ ...this.state, pending: false });
        } catch (err) {
          this.setState({ ...this.state, pending: false, error: true });
        }
      })();
  }

  render() {
    if (this.state.pending)
      return <PendingScreen title="App wird geladen..." />;
    if (this.state.error) return <p>Fehler!</p>;

    if (window.location.pathname === "/preview")
      return (
        <div className="App">
          <Container notFluid>
            <Preview />
          </Container>
        </div>
      );
    return (
      <div className="App">
        <Container notFluid>
          <Header />
          <main
            style={{
              height: "calc(100vh - 160px)",
              padding: "16px 0px",
              boxSizing: "border-box"
            }}
          >
            <Grid justify="center">
              <div
                xs={10}
                md={8}
                lg={6}
                style={{ backgroundColor: "#fff", borderRadius: "4px" }}
              >
                <Registration offline={this.state.offline} />
              </div>
            </Grid>
          </main>
          <footer></footer>
        </Container>
      </div>
    );
  }
}

export default App;
