import React from "react";

export default function Logo() {
  return (
    <div style={{ textAlign: "left" }}>
      <span className="logo-font">
        13TH
        <br />
        YOUNG
        <br />
        ART
        <br />
        AUCTION
      </span>
    </div>
  );
}
