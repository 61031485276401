import React from "react";
import { Grid } from "@nodeme/grid-react";
import { buildClasses } from "@nodeme/jss-react";
import Logo from "./Logo";
import logo from "../logo.svg";

const styles = theme => definitions => {
  return {
    artcare: {
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        textAlign: "right",
        left: "0px",
        top: "25px"
      }
    },
    buttons: {
      padding: "0px",
      [theme.breakpoints.up("sm")]: {
        padding: "10px 0px"
      }
    }
  };
};

const LandingPage = props => {
  const classes = buildClasses(styles);
  return (
    <header style={{ textAlign: "left", padding: "16px" }}>
      <Grid>
        <Logo xs={5} sm={5} />
        <div xs={5} sm={5} className={classes.artcare}>
          <img src={logo} className="App-logo" alt="artcare-logo" />
        </div>
      </Grid>
    </header>
  );
};

export default LandingPage;
