import React from "react";
import { buildClasses } from "@nodeme/jss-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";

const style = theme => definitions => ({
  holder: {
    position: "absolute",
    display: "table",
    height: "100%",
    width: "100%"
  },
  aligner: {
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    color: "#fff"
  },
  icon: {
    color: theme.palette.get("primary")
  }
});

const PendingScreen = props => {
  const classes = buildClasses(style, props);
  return (
    <div className={classes.holder}>
      <div className={classes.aligner}>
        <FontAwesomeIcon
          icon={faSpinner}
          style={{ fontSize: "5rem", marginBottom: "16px" }}
          spin
        />
        <br />
        {props.title}
      </div>
    </div>
  );
};

export default PendingScreen;
